body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
}

p {
  color: rgba(0, 0, 0, 0.80);
  font-family: 'Poppins', sans-serif;
}

h1, h2, h3, h4, h5 {
  font-family: 'Poppins', sans-serif;
}
