
.footer {

}


.footerCardFirst {
    height: 150px;
    padding: 20px;
    margin: 20px 10px;

    display: flex;
    align-items: center;
    justify-content: center;
}
.footerCardFirst img {
    width: 200px;
}

.footerCard{
    border-top: 2px solid #000000;
    padding: 10px 20px;
    margin: 20px 10px;
    height: 150px;
    text-align: center;
}

.footerCard img {
    width: 200px;
}

.footerCard h1 {
    font-size: 20px;
}

.footerCard p {
    font-size: 15px;
}


.footerText {
    text-align: center;
    margin: 30px 0;
    padding: 20px 0;
}

.footerText p {
    font-size: 11px;
}

.footerCopyRight {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
}

.footerCopyRight p{
    margin: 0;
    padding: 0 10px;
    font-size: 12px;
}


@media screen and (min-width: 768px) {
    .footerCard {
        border-top: none;
        border-left: 2px solid #000000;
        text-align: left;
    }
}