.information {

}

.informationText {
    padding: 100px 0;
    max-width: 500px;
    text-align: left;
    font-style: italic;
}

.informationText p {
    padding: 10px 0;
}
