
.header {
    height: 600px;

    background: url("../../assets/headerBackground.jpg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;

    position: relative;
}

.headerShadow {
    position: absolute;
    background: rgba(18, 13, 13, 0.90);

    width: 100%;
    height: 100%;
}

.headerText {
    color: #FFFFFF;

    position: absolute;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;

    max-width: 500px;
    height: 100%;
    z-index: 500;
}
.headerTitle h1{
    font-size: 40px;
    font-weight: 600;
}

@media screen and (min-width: 768px) {

    .headerTitle h1{
        font-size: 60px;
    }

    .headerText {
        align-items: start;
        text-align: left;
        max-width: 600px;
    }

}

@media screen and (min-width: 992px) {
    .header {
        height: 800px;
    }

}