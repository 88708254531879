.navbar {
    padding: 20px 0;

}

.mobileNavbar, .desktopNavbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.navbarLogo {

}
.navbarLogo img{
    width: 200px;
}

.navbarIcons i {
    font-size: 20px;
    padding: 10px;
}

.desktopNavbar {
    display: none;
}

.navbarInfo {
}


.navbarInfo p {
    padding-left: 20px;
    margin: 0;
}

@media screen and (min-width: 992px) {
    .mobileNavbar {
        display: none;
    }
    .desktopNavbar {
        display: flex;
    }

}